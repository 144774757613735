import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M4977 9414 c-4 -4 -7 -272 -7 -596 l0 -588 -27 0 c-46 0 -252 -46
-358 -80 -186 -60 -375 -163 -517 -283 -130 -111 -252 -307 -300 -482 -28
-103 -31 -350 -6 -462 91 -403 432 -653 1125 -826 98 -25 197 -48 221 -52 l42
-7 0 60 0 59 -137 32 c-178 42 -494 146 -604 199 -276 132 -444 316 -500 548
-30 122 -30 305 0 419 104 398 487 671 1063 755 73 11 139 20 146 20 8 0 12
19 12 61 l0 61 -42 -4 -43 -3 -5 585 -5 585 -25 3 c-14 2 -29 0 -33 -4z"/>
<path d="M5207 9174 c-4 -4 -7 -1271 -7 -2816 0 -2591 1 -2809 16 -2815 9 -3
24 -2 33 4 15 9 16 245 16 2819 l0 2809 -25 3 c-14 2 -29 0 -33 -4z"/>
<path d="M5426 8924 c-3 -9 -6 -162 -6 -340 l0 -324 -65 0 -65 0 0 -60 0 -60
143 0 c314 0 676 -68 977 -184 91 -35 249 -112 312 -152 59 -38 98 -30 128 26
16 30 2 48 -72 91 -237 139 -609 262 -931 308 l-122 17 -5 225 -5 224 -30 0
-30 0 -3 -224 -2 -223 -31 6 c-18 3 -53 6 -80 6 l-49 0 0 340 0 340 -29 0
c-17 0 -31 -6 -35 -16z"/>
<path d="M5312 6068 l3 -52 70 -18 c39 -10 194 -50 347 -88 584 -148 817 -255
1015 -463 119 -126 170 -257 180 -463 6 -140 -11 -247 -58 -358 -135 -318
-487 -544 -970 -625 -158 -27 -322 -41 -471 -41 l-118 0 0 -60 0 -60 114 0
c429 0 820 86 1101 241 385 213 586 566 545 959 -19 191 -66 314 -167 441
-209 261 -557 414 -1345 589 -126 27 -233 50 -238 50 -7 0 -10 -20 -8 -52z"/>
<path d="M3646 4499 c-42 -33 -34 -60 36 -124 287 -259 881 -484 1379 -521
l89 -7 0 62 0 61 -47 0 c-137 1 -407 55 -621 125 -271 90 -552 238 -705 374
-64 56 -90 62 -131 30z"/>
<path d="M1520 2841 c-232 -34 -360 -145 -360 -311 0 -175 104 -247 470 -325
178 -38 250 -80 250 -145 0 -42 -37 -90 -88 -113 -41 -19 -64 -22 -177 -22
-148 1 -216 15 -333 72 -41 19 -75 33 -77 31 -1 -2 -19 -35 -39 -75 l-36 -72
43 -26 c108 -62 264 -97 442 -96 257 0 417 76 472 224 18 50 15 146 -6 192
-46 102 -148 151 -442 214 -148 32 -205 55 -238 97 -43 55 -14 126 66 165 38
18 69 24 146 27 113 5 191 -8 295 -48 l74 -29 35 76 c41 85 43 80 -47 114
-119 45 -325 68 -450 50z"/>
<path d="M9070 2841 c-232 -34 -360 -145 -360 -311 0 -175 104 -247 470 -325
178 -38 250 -80 250 -145 0 -42 -37 -90 -88 -113 -41 -19 -64 -22 -177 -22
-148 1 -216 15 -333 72 -41 19 -75 33 -77 31 -1 -2 -19 -35 -39 -75 l-36 -72
43 -26 c108 -62 264 -97 442 -96 257 0 417 76 472 224 18 50 15 146 -6 192
-46 102 -148 151 -442 214 -148 32 -205 55 -238 97 -43 55 -14 126 66 165 38
18 69 24 146 27 113 5 191 -8 295 -48 l74 -29 35 76 c41 85 43 80 -47 114
-119 45 -325 68 -450 50z"/>
<path d="M2330 2518 c0 -355 7 -427 51 -515 79 -163 242 -245 484 -245 244 0
411 85 485 245 43 92 50 161 50 510 l0 317 -109 0 -109 0 -4 -342 c-4 -292 -7
-350 -21 -387 -26 -68 -61 -107 -124 -138 -53 -26 -67 -28 -168 -28 -100 0
-115 3 -161 27 -56 29 -95 70 -122 127 -14 31 -17 83 -20 389 l-3 352 -115 0
-114 0 0 -312z"/>
<path d="M3730 2300 l0 -531 393 4 c335 3 401 6 452 21 158 46 234 133 235
266 0 115 -62 206 -167 246 l-42 16 47 33 c31 21 56 50 75 86 25 47 28 63 25
125 -3 57 -9 79 -31 112 -36 56 -96 97 -176 124 -62 21 -87 22 -438 26 l-373
3 0 -531z m685 360 c21 -6 54 -27 72 -45 29 -29 33 -39 33 -85 0 -47 -4 -56
-33 -84 -53 -49 -91 -57 -319 -63 l-208 -6 0 153 0 152 208 -5 c123 -2 223 -9
247 -17z m43 -445 c100 -30 147 -100 120 -180 -14 -43 -56 -77 -117 -96 -20
-6 -141 -13 -268 -16 l-233 -6 0 157 0 156 223 0 c171 0 235 -4 275 -15z"/>
<path d="M5080 2300 l0 -530 115 0 115 0 0 155 0 155 198 0 c165 0 212 3 276
20 140 36 237 105 287 205 21 43 24 64 24 150 0 82 -4 109 -22 147 -30 65
-102 134 -177 169 -111 52 -169 59 -508 59 l-308 0 0 -530z m651 341 c54 -20
105 -64 128 -108 20 -40 14 -132 -13 -178 -50 -85 -152 -115 -393 -115 l-143
0 0 216 0 217 183 -6 c151 -4 191 -9 238 -26z"/>
<path d="M6340 2300 l0 -530 435 0 436 0 -3 83 -3 82 -317 3 -318 2 0 445 0
445 -115 0 -115 0 0 -530z"/>
<path d="M7380 2518 c0 -355 7 -427 51 -515 79 -163 242 -245 484 -245 244 0
411 85 485 245 43 92 50 161 50 510 l0 317 -109 0 -109 0 -4 -342 c-4 -292 -7
-350 -21 -387 -26 -68 -61 -107 -124 -138 -53 -26 -67 -28 -168 -28 -100 0
-115 3 -161 27 -56 29 -95 70 -122 127 -14 31 -17 83 -20 389 l-3 352 -115 0
-114 0 0 -312z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
